import { useEffect, useState } from "react";
import { ErrorSetter } from "../Components/ErrorBoundary";
import { fetchWithAuth } from "../Helpers/Fetch";

export function useAlbums(options: { setError: ErrorSetter }) {
  const { setError } = options;
  const [albums, setAlbums] = useState<Record<string, Album>>({});
  useEffect(() => {
    let disposed = false;
    (async () => {
      try {
        const res = await fetchWithAuth<Record<string, Album>>(
          "https://yzhk.blob.core.windows.net/ian/albums.json",
          {
            scope: "https://storage.azure.com/user_impersonation",
            headers: {
              "x-ms-version": "2020-08-04",
            },
          }
        );
        !disposed && setAlbums(res);
      } catch (e) {
        !disposed && setError(e);
      }
    })();
    return () => {
      disposed = true;
    };
  }, [setAlbums, setError]);

  return albums;
}

interface Album {
  url: string;
  minDate?: string;
  maxDate?: string;
}
