import { PublicClientApplication } from "@azure/msal-browser";

const clientId = process.env.REACT_APP_CLIENT_ID!;
const tenantId = process.env.REACT_APP_TENANT_ID;
const authority = `https://login.microsoftonline.com/${tenantId}`;

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId,
    authority,
  },
});
