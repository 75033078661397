import { memo } from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";

interface Props {
  error: any;
}

const ErrorMessageBar = (props: Props) => {
  const { error } = props;
  if (!error) {
    return null;
  }

  let errorMessage: string = "";
  if (typeof error === "string") {
    errorMessage = error;
  } else if (typeof error?.message === "string") {
    errorMessage = error.message;
  } else if (error instanceof Error) {
    errorMessage = `${error.name}: ${error.message}`;
  } else {
    errorMessage = JSON.stringify(error);
  }

  return (
    <MessageBar messageBarType={MessageBarType.error}>
      {errorMessage}
    </MessageBar>
  );
};

export default memo(ErrorMessageBar);
