import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { msalInstance } from "../Helpers/Msal";
import Loadable from "./Loadable";
import { PhotoPrismRedirect } from "./PhotoPrismRedirect";

const LegacyHome = lazy(() => import("../Legacy/Components/Home"));

export const AppRouter = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Router>
          <Suspense fallback={<Loadable loading />}>
            <Routes>
              <Route path="/legacy" element={<LegacyHome />} />
              <Route
                path="/china"
                element={<PhotoPrismRedirect albumName="China" />}
              />
              <Route
                path="/"
                element={<PhotoPrismRedirect albumName="BabyIan" />}
              />
            </Routes>
          </Suspense>
        </Router>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
