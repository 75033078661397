import { msalInstance } from "./Msal";

export async function fetchWithAuth<T>(
  input: RequestInfo,
  init: RequestInit & { scope: string }
): Promise<T> {
  const { scope, ...fetchInit } = init;
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  const { accessToken } = await msalInstance.acquireTokenSilent({
    scopes: [scope],
    account: activeAccount || accounts[0],
  });
  const res = await fetch(input, {
    ...fetchInit,
    headers: {
      ...init.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const json: T = await res.json();
  if (!res.ok) {
    throw json;
  }

  return json;
}
