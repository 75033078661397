import { useAccount, useMsal } from "@azure/msal-react";
import { ThemeProvider } from "@fluentui/react";
import { memo, useEffect, useState } from "react";
import { msalInstance } from "../Helpers/Msal";
import { useAlbums } from "../Hooks/useAlbums";
import ErrorMessageBar from "./ErrorMessageBar";

interface Props {
  albumName: string;
}

export const PhotoPrismRedirect = memo((props: Props) => {
  const { albumName } = props;
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  useEffect(() => {
    account && msalInstance.setActiveAccount(account);
  }, [account]);
  const [error, setError] = useState<any>();
  const albums = useAlbums({ setError });
  const album = albums?.[albumName];
  useEffect(() => {
    if (album) {
      window.location.href = album.url;
    }
  }, [album]);

  return (
    <ThemeProvider>
      <ErrorMessageBar error={error} />
    </ThemeProvider>
  );
});
