import { memo, PropsWithChildren } from "react";
import { Spinner } from "@fluentui/react";

interface Props {
  loading: boolean;
  message?: string;
}

const Loadable = (props: PropsWithChildren<Props>) => {
  const { loading, message, children } = props;
  return loading ? (
    <Spinner label={message || "Loading"} labelPosition="right" />
  ) : (
    <>{children}</>
  );
};

export default memo(Loadable);
